var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" Title Wise Due Report "),_c('v-spacer'),(_vm.downloadUrl && _vm.form.items.data.length)?_c('print-buttons',{attrs:{"passAuthToReport":true,"downloadUrl":_vm.downloadUrl+`&filter_type=${this.report_type}&type=`,"pdf":true,"excel":true,"labelPdf":"Download Pdf","labelExcel":"Download Excel"}}):_vm._e()],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title mt-3"},[_c('v-flex',{attrs:{"xs2":"","sm2":""}},[_c('v-calendar-field',{attrs:{"id-val":"from_date","label":"From Date"},model:{value:(_vm.frmDate),callback:function ($$v) {_vm.frmDate=$$v},expression:"frmDate"}})],1),_c('v-flex',{attrs:{"xs2":"","sm2":""}},[_c('v-calendar-field',{attrs:{"id-val":"to_date","label":"To Date"},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-grade-field',{attrs:{"label":"Select Grades","with-all":true},on:{"selectedGrade":_vm.selectedGrade},model:{value:(_vm.gradeId),callback:function ($$v) {_vm.gradeId=$$v},expression:"gradeId"}})],1),_c('v-flex',{attrs:{"xs2":""}},[_c('v-section-field',{attrs:{"grade-id":_vm.gradeId,"label":"Select Section"},model:{value:(_vm.sectionId),callback:function ($$v) {_vm.sectionId=$$v},expression:"sectionId"}})],1),_c('v-flex',{attrs:{"xs2":"","sm2":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"disabled":!_vm.gradeId,"items":[
                  { value: 'all', text: 'All' },
                  { value: 'left', text: 'Left Student' },
                  { value: 'current', text: 'Current Student' },
                ],"label":"Filter Type","outlined":"","dense":""},model:{value:(_vm.report_type),callback:function ($$v) {_vm.report_type=$$v},expression:"report_type"}})],1),_c('search-button',{attrs:{"disabled":false,"permission":"receipt-read"},on:{"action":function($event){return _vm.get()}}},[_vm._v(" Search ")])],1),(_vm.form.items.data.length)?_c('div',{staticClass:"data-represent",staticStyle:{"margin-top":"-10px"}},[_c('div',[_c('span',{staticClass:"d-error"}),_vm._v("   "),_c('small',[_c('strong',[_vm._v(_vm._s(_vm.totalSum.currency()))]),_vm._v(" Total Due")])]),_c('div',[_c('span',{staticClass:"d-info"}),_vm._v("   "),_c('small',[_vm._v("Generated "),_c('strong',[_vm._v(_vm._s(_vm.form.items.data.length))]),_vm._v(" Heads ")])])]):_vm._e(),_c('br')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.form.items.data,"hide-default-footer":"","options":_vm.pagination,"footer-props":_vm.footerProps,"server-items-length":_vm.form.items.data.length,"loading":_vm.form.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ index, item }){return [_c('tr',{staticStyle:{"cursor":"pointer"}},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',{staticClass:"text-xs-left"},[_c('strong',[_vm._v(_vm._s(item.fee_head))]),_c('small',{staticStyle:{"color":"#777","font-weight":"bold","display":"block","margin-top":"-1px"}},[_vm._v(" "+_vm._s(item.fee_head_count)+"     "),_c('span',{staticStyle:{"color":"#999","padding-left":"2px","font-weight":"normal"}},[_vm._v(" time(s) ")])])]),_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(item.due_amount_sum.currency())+" ")]),_c('td',{staticClass:"text-right"},[_c('view-button',{attrs:{"permission":true,"label":"Details"},on:{"action":function($event){return _vm.viewDetails({
                      title: item.fee_head,
                      gradeId: _vm.gradeId,
                      sectionId: _vm.sectionId,
                      fee_head_id: item.fee_head_id,
                      frm_date: _vm.frmDate,
                      to_date: _vm.toDate,
                    })}}})],1)])]}},{key:"body.append",fn:function(){return [(_vm.totalSum > 0)?_c('tr',[_c('td',{attrs:{"colspan":_vm.headers.length - 2}},[_c('strong',[_vm._v("Total:")])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.totalSum.currency()))])]),_c('td')]):_vm._e()]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }