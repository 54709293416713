<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Title Wise Due Report
            <v-spacer></v-spacer>
            <print-buttons
              :passAuthToReport="true"
              v-if="downloadUrl && form.items.data.length"
              :downloadUrl="downloadUrl+`&filter_type=${this.report_type}&type=`"
              :pdf="true"
              :excel="true"
              labelPdf="Download Pdf"
              labelExcel="Download Excel"
            ></print-buttons>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title mt-3">
              <v-flex xs2 sm2>
                <v-calendar-field
                  id-val="from_date"
                  v-model="frmDate"
                  label="From Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs2 sm2>
                <v-calendar-field
                  id-val="to_date"
                  v-model="toDate"
                  label="To Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs2>
                <v-grade-field
                  label="Select Grades"
                  :with-all="true"
                  @selectedGrade="selectedGrade"
                  v-model="gradeId"
                ></v-grade-field>
              </v-flex>

              <v-flex xs2>
                <v-section-field
                  v-model="sectionId"
                  :grade-id="gradeId"
                  label="Select Section"
                ></v-section-field>
              </v-flex>
              <v-flex xs2 sm2>
                <v-select
                  :disabled="!gradeId"
                  :items="[
                    { value: 'all', text: 'All' },
                    { value: 'left', text: 'Left Student' },
                    { value: 'current', text: 'Current Student' },
                  ]"
                  class="pa-0"
                  label="Filter Type"
                  v-model="report_type"
                  outlined
                  dense
                />
              </v-flex>

              <search-button
                
                :disabled="false"
                style=""
                permission="receipt-read"
                @action="get()"
              >
                Search
              </search-button>
            </v-card-title>

            <div
              class="data-represent"
              style="margin-top: -10px"
              v-if="form.items.data.length"
            >
              <div>
                <span class="d-error"></span> &nbsp;
                <small>
                  <strong>{{ totalSum.currency() }}</strong> Total Due</small
                >
              </div>
              <div>
                <span class="d-info"></span> &nbsp;
                <small
                  >Generated <strong>{{ form.items.data.length }}</strong> Heads
                </small>
              </div>
            </div>
            <br />
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            hide-default-footer
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.data.length"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr style="cursor: pointer">
                <td>{{ ++index }}</td>
                <td class="text-xs-left">
                  <strong>{{ item.fee_head }}</strong>
                  <small
                    style="
                      color: #777;
                      font-weight: bold;
                      display: block;
                      margin-top: -1px;
                    "
                  >
                    {{ item.fee_head_count }} &nbsp;&nbsp;&nbsp;
                    <span
                      style="
                        color: #999;
                        padding-left: 2px;
                        font-weight: normal;
                      "
                    >
                      time(s)
                    </span>
                  </small>
                </td>
                <td class="text-xs-left">
                  {{ item.due_amount_sum.currency() }}
                </td>
                <td class="text-right">
                  <view-button
                    @action="
                      viewDetails({
                        title: item.fee_head,
                        gradeId: gradeId,
                        sectionId,
                        fee_head_id: item.fee_head_id,
                        frm_date: frmDate,
                        to_date: toDate,
                      })
                    "
                    :permission="true"
                    label="Details"
                  />
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <tr v-if="totalSum > 0">
                <td :colspan="headers.length - 2">
                  <strong>Total:</strong>
                </td>
                <td>
                  <strong>{{ totalSum.currency() }}</strong>
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    
    form: new Form(
      {
        sod_date: nd.format("YYYY-MM-DD"),
      },
      "/api/report/title-wise-due-report"
    ),
    pagination: {
      rowsPerPage: 100,
      descending: true,
    },
    frmDate:
      nd.getYear() + "-" + nd.getMonth().pad() + "-" + nd.getDate().pad(),
    toDate: nd.format("YYYY-MM-DD"),
    gradeId: "all",
    sectionId: "",
    downloadUrl: "",
    totalSum: 0,
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Fee Head", align: "left", value: "fee_head", sortable: false },
      {
        text: "Amount",
        align: "left",
        value: "amount",
        width: 200,
        sortable: false,
      },
      {
        text: "Action",
        align: "right",
        value: "view_details",
        width: 150,
      },
    ],

    report_type:'all',
  }),

  methods: {
    selectedGrade(grade) {
      let sectionId = "";
      this.sectionId = "";
      grade.sections.map((item) => {
        sectionId += item.id + ",";
      });
      this.sectionId = sectionId;
    },
    get() {
      let extraParam = `fromDate=${this.frmDate}&toDate=${this.toDate}&gradeId=${this.gradeId}&sectionId=${this.sectionId}&filter_type=${this.report_type}`;

      this.form.get(null, this.queryString(extraParam)).then(({ data }) => {
        this.totalSum = 0;
        data.data.map((res) => {
          this.totalSum += parseFloat(res.due_amount_sum);
        });
        this.pagination.totalItems = data.data.length;
        this.downloadUrl = data.download_url;
      });
    },
    viewDetails(params) {
      this.$router.push({
        name: "title-wise-due-report-details",
        query: params,
      });
    },
  },
};
</script>
<style lang="scss"></style>
